$darkBlue: #003f80;
$tenueBlue: #d5e3f2;
$whiteBlue: #ccdce4;
$whiteGray: #a9abae;
$darkGray: #606062;
$parragraphColor: #606062;
$red: #ed3237;
$tableWithoutSelect: #003f80 0.6;
$tableSelect: #003f80 0.2;
$font-Secular: "Roboto";

@import "./components/Layouts/pagination.scss";
@import "./styleConstants.scss";

body {
  //height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Roboto";
}
h1 {
  font-weight: 700;
}

.buttonNewOrCreate:hover {
  .buttonNewOrCreate_img {
    filter: none;
  }
  background-color: rgba(111, 156, 255, 0.15);
  color: #4d84fe;
  filter: none;
}
/* BOOTSTRAP COLOR CAHNGE */
// .btn-primary {
//   background-color: $darkBlue;
// }

.title-blue {
  color: #01a0f6;
  font-style: bold;
  font-weight: 700;
  font-size: 48px;
  line-height: 48px;
  display: flex;
  align-items: center;
  padding-bottom: 0px;
}
.title-black {
  width: 120px;
  height: 48px;
  font-size: 48px;
  font-weight: 400;
  font-style: normal;
  display: flex;
  align-items: center;
  color: #58595b;
}
.pagination {
  color: $clearBlue;
  float: right;
}
.text-primary {
  color: $darkBlue !important;
}
.hoverPointer:hover {
  cursor: pointer;
}
.margin-left-3rem {
  margin-left: 3rem;
}

.pagination-button {
  border: 1px solid #ddd;
  a {
    color: white !important;
    padding: 1rem;
    font-weight: bolder;
    font-size: 1rem;
  }
}
.btn-following {
  background-color: $tenueBlue !important;
}

.btn-secondary {
  background-color: $darkGray !important;
}
.input-blue-border {
  border: 2.5px solid #01a0f6;
  border-radius: 10px;
  color: #005dbf !important;
  font-weight: 700;
}
.background-ttn {
  width: 100%;
  height: 100vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-image: url(../src/assets/img/icons/fondoTTN.svg);
}
.circle-red {
  background: red;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  margin: auto;
}
.hover-table-row:hover {
  background-color: $tenueBlue;
  transform: scale(1.003);
  filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.1));
  transition: 0.3s all ease-in-out;
}
.float-right {
  float: right;
}
.icons-svg-top-table {
  margin: 0 10px;
  width: 1.7rem;
}
.pop-edit-record {
  position: absolute;
  z-index: 2;
  margin-left: 10rem;
  margin-top: 0;
}
.icons-gear {
  width: 1.5rem;
  // margin: 10px;
}
.icons-edit-record {
  width: 1.5rem;
  padding: 0 0.5rem;
}

.icon-family {
  width: 1.5rem;
  margin-bottom: 5px;
  margin-left: 1%;
}

//CSS meant to edit the react-popup library
// .popUp-ul {
//   margin: 0 !important;
//   list-style: none;
//   padding-left: 0 !important;
//   width: 10rem r !important;
// }

[role="tooltip"].popup-content {
  max-width: 10rem;
  //width: 150px;
  //height: 4rem;
  border-radius: 0px 5px 5px 5px;
  // border: 1px solid $darkBlue;
  background: $white;
  padding: 0 0.4rem;
}
.popUp-ul {
  margin: 0 !important;
  list-style: none;
  padding-left: 0 !important;
  width: 10rem r !important;
}
[role="tooltip"].popup-content {
  width: 150px;
}
.icons-popUp {
  width: 1rem;
  margin-right: 1rem;
}

.icon-family {
  width: 1.5rem;
  margin-bottom: 5px;
  margin-left: 1%;
}

//width: 10rem;
//height: 4rem;
//border-radius: 0;
//border: 1px solid $darkBlue;
//background: $white;
// padding: 0 .4rem;
////}
.popup-arrow {
  display: none;
}
// .icons-popUp {
//   width: 1rem;
//   margin-right: 1rem;
// }

.circle-red-tools {
  background: red;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
}
.click-me:hover {
  cursor: pointer;
  opacity: 0.8;
}
.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.titleTable {
  margin-left: 10%;
}
.input-big-height {
  height: 2.6rem;
}
.ml-75 {
  margin-left: 0.75rem;
}
.ml-3 {
  margin-left: 1rem;
}
.ml-2rem {
  margin-left: 2rem;
}
.ml-5 {
  margin-left: 3rem;
}
.ml-5rem {
  margin-left: 5rem;
}

.loading {
  z-index: 1059;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  margin-top: auto;
  top: 40%;
  text-align: center;
}

.loading::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: -1;
}

.ml-3 {
  margin-left: 1rem;
}
.ml-2rem {
  margin-left: 2rem;
}
.ml-5 {
  margin-left: 3rem;
}
.ml-5rem {
  margin-left: 5rem;
}
@media (max-width: 1640px) {
  .titleTable {
    margin-left: 2%;
  }
}
.titlesText {
  padding-left: 60px;
}
.textForgotPass {
  align-self: baseline;
}
.btnOutlineDarkBlue {
  transition: 0.3s all ease-in-out;
  color: rgba(0, 93, 191, 1);
  border: 1.5px solid #005dbf;
  border-radius: 5px;
  font-weight: 700;
  min-width: 110px;
  min-height: 19px;
  max-height: 40px;
}

.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

.swal2-styled:focus {
  outline: 0;
  box-shadow: 0 0 0 3px rgba(100, 150, 200, 0);
}

.groupBounding {
  margin-left: 0.5rem;
  display: inline-block;
  padding: 0.5rem 0.3rem;
  transition: 0.3s all ease-in-out;
  min-width: 9.563rem; // 153px;
}
.groupBounding:hover {
  cursor: pointer;
  background: rgba(230, 236, 242, 1);
  border-radius: 10px;
  color: rgba(0, 93, 191, 1);
}
.groupBounding:hover .btnAddTable {
  filter: grayscale(0%);
}
.groupBounding a {
  color: rgba(88, 89, 91, 1);
  transition: 0.2s all ease-in-out;
}
.groupBounding a:hover {
  color: rgba(0, 93, 191, 1);
}
.btnAddTable {
  width: 0.973rem; //15.56px
  margin: 0 10px;
  filter: grayscale(100%);
}

.inputsearch {
  min-width: 16.875rem; //270px;
  max-height: 1.5rem; //24px;
  background-color: rgba(0, 93, 191, 0.1);
  border-radius: 6px;
  border: none;
}
.inputsearch::placeholder {
  color: $darkBlue;
}

.text-light-blue {
  color: $clearBlue;
}
.input-text-light-blue {
  border: 1px solid #01a0f6;
  border-radius: 6px;
  max-height: 2rem;
}

.selectInput-react-select .css-yk16xz-control {
  border-radius: 6px;
  border: 1px solid $clearBlue;
  // max-height: 2rem;
}
.paddingLeft1rem {
  padding-left: 1rem;
}
.w-90 {
  width: 90%;
}
.input-text-darker-blue {
  color: $darkerBlue;
}
.tooltip > div {
  background-color: #fff !important;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
  color: #000;
}
.text-primary {
  color: #005dbf !important;
}

.mr-1 {
  margin-right: 0.25rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mr-3 {
  margin-right: 1rem;
}
.form-check-input[type="radio"] {
  border: 2px solid #005dbf;
}
.form-check-input:checked[type="radio"] {
  background-color: #fff;
  border: 2px solid #005dbf;
  position: relative;
}
.form-check-input:checked[type="radio"]::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #005dbf;
  position: absolute;
  left: 1px;
  top: 1px;
  border-radius: 100%;
}
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $whiteGray;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $darkGray;
}

.swal2-styled:focus {
  box-shadow: none;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $darkGray;
}

.font-size-label {
  font-size: 12px;
}
.bg-dark-blue {
  background-color: rgba(0, 44, 144, 1);
}
.swal2-cancel {
  border: 0;
  border-radius: 0.25em;
  background-color: #005dbf !important;
  color: #fff;
  font-size: 1em;
  transition: 0.3s all ease-in-out;
}
.swal2-cancel:hover {
  background-color: #002c91 !important;
}
.swal2-confirm {
  border: 1.5px solid #01a0f6 !important;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #fff !important;
  color: #01a0f6 !important;
  font-size: 1em;
  transition: 0.3s all ease-in-out;
}
.swal2-confirm:hover {
  border: 1.5px solid #01a0f6;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #01a0f6 !important;
  color: #fff !important;
  font-size: 1em;
  transition: 0.3s all ease-in-out;
}

.font-size-label {
  font-size: 12px;
}
.bg-dark-blue {
  background-color: rgba(0, 44, 144, 1);
}
.swal2-cancel {
  border: 0;
  border-radius: 0.25em;
  background-color: #005dbf !important;
  color: #fff;
  font-size: 1em;
  transition: 0.3s all ease-in-out;
}
.swal2-cancel:hover {
  background-color: #002c91 !important;
}
.swal2-confirm {
  border: 1.5px solid #01a0f6 !important;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #fff !important;
  color: #01a0f6 !important;
  font-size: 1em;
  transition: 0.3s all ease-in-out;
}
.swal2-confirm:hover {
  border: 1.5px solid #01a0f6;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #01a0f6 !important;
  color: #fff !important;
  font-size: 1em;
  transition: 0.3s all ease-in-out;
}
.customTabs__Footer {
  margin: 20px 0;
  display: inline-flex;
  flex-direction: row-reverse;
  width: 100%;
  vertical-align: middle;
  // justify-content: space-between;
  padding: 0 0.5rem;
}
.customTabs__Footer > Button {
  margin-left: 12px;
}
