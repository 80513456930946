
body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    background-color: #F8FAFB;
    font-family: 'Roboto';
  }

  .backgroundColorApp {
    background-color:  #F8FAFB;
  }

  /*JOSEGOMEZ*/
  input[type=checkbox]{
    border: 0.15em solid #4d84fe !important;
    width: 1em;
    height: 1em;
    background-color: white;
    border-radius: 3px;
    vertical-align: middle;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
  }
  input[type=checkbox]:checked{
    background-color: #4d84fe;
  }
  .text-readonly{
    font-weight: bold;
    color:#4d84fe !important;
    background-color: rgba(111, 156, 255, 0.15);
  }
.div-document-title-header{
  padding:10px;
  width:100%;
  text-align: center;
}
.fontBold{
  font-weight: bold;
}
.hr-document-title-header{
  background-color: #4d84fe;
}
.hr-document-title-header, .div-document-title-header{
  margin:0 !important;
}
  .buttonNewOrCreate{
    cursor: pointer;
    padding:10px;
    padding-right:0px;
    border-radius:13px;
    border: 1px solid transparent;
  }
  .accordionToggleButton:focus{
    outline: 0;
  }
  .ml-4{
    margin-left: 2rem !important;
  }
  .ml-1{ margin-left: 0.5rem !important; }
  .buttonNewOrCreate>img{
    filter: grayscale(100%);
  }
  .inputDocumentTitle::placeholder{
    color:#8B8D94;
  }
  .inputDocumentTitle:focus,.selectDocumentTitle:focus  {  outline: none;  }
  
  .inputDocumentTitle,.selectDocumentTitle{
    margin-left:-50px;
  }
  .inputDocumentTitle{
    font-size:26px;
    font-weight:500;
    padding-left:0px;
    border:1px solid transparent !important;
    border-radius:3px;
    margin-bottom: 0px !important;
  }
  .selectDocumentTitle, .selectDocumentTitle::placeholder{
    color:#8B8D94;
    font-size: 20px;
  }
  .selectDocumentTitle{
    min-width: 100%;
    border:1px solid transparent;
  }
  .filterDiv-ListView>div{
    margin-right:20px;
  }
  .filterDiv-ListView>button{
    margin-right:0px;
  }
  input[type=text],input[type=date], .reactSelect, select {
    min-height: 30px;
    /* min-width:150px; */
  }
  input[type=text],input[type=date], select, .reactSelect{
    color:#373D50;
  }
  .btn_tabs:focus{
    box-shadow: none !important;
  }
  .div-versionamiento-textLike{
    padding:9px;
    font-size:13px;
    background-color: #F6F9FF;
    border-radius: 6px;
    border: 1px solid #3471F3;
    min-height: 180px;
    max-height: 180px;
    overflow: auto;
  }
  /**/

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  