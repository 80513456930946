@import "../../styleConstants.scss";

$sidebar-bg-color: $sideBarBlue;

// $sidebar-bg-color: $darkBlue;
// $sidebar-color: #58595b !default; //affects all text and icons of the sidebar
// $sidebar-width: 180px !default;
$sidebar-collapsed-width: 70px !default;

//$highlight-color: #58595b !default;
$highlight-color: #ffffff !default;

$submenu-bg-color: $sideBarBlue !default;

$submenu-bg-color-collapsed: $sideBarBlue !default; //not needed because submenu is size 0 when collapsed

$icon-bg-color: $sideBarBlue !default;

$icon-size: 30px !default;

//we are not using these break points yet
// $breakpoint-xs: 480px !default;
// $breakpoint-sm: 576px !default;
// $breakpoint-md: 768px !default;
// $breakpoint-lg: 992px !default;
// $breakpoint-xl: 1200px !default;

.icon-sidebar {
  transform: scale(2);
}
// .full-height{
//     min-height: 100vh;
// }

@import "~react-pro-sidebar/dist/scss/styles.scss";
//PS: i hate this library, lets never use it again -at: Ed

.pro-menu-item.pro-sub-menu.open::after {
  //.pro-inner-item-active
  content: " ";
  display: block;
  position: absolute;
  height: 1px;
  background: $whiteGray;
  // width: -webkit-fill-available;
  margin: 0 10%;
}

// .pro-sidebar:not(.collapsed) .pro-sub-menu.pro-menu-item.open > .pro-inner-item {
//     background-color: $darkBlue;
//     color: $white  ;
// }

// .pro-sidebar:not(.collapsed) .pro-sub-menu.pro-menu-item.open > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
//     border-color: $white;
// }

.pro-sidebar:not(.collapsed) {
  width: 335px !important;
}
// .pro-sidebar.collapsed{ width: 100px !important; }
@import "~react-pro-sidebar/dist/scss/styles.scss";

.pro-sidebar:not(.collapsed)
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item:before,
.popper-inner .pro-menu-item .pro-inner-item:before {
  display: none !important;
}

.pro-sidebar:not(.collapsed) .pro-menu .pro-item-content,
.popper-inner .pro-menu-item {
  text-transform: uppercase;
  font-weight: 500;
}

.pro-sidebar:not(.collapsed)
  .pro-menu-item.open
  > .pro-inner-item
  > .pro-icon-wrapper {
  // background-color: $darkBlue ;
  color: $white;
}

// .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
//     padding: 0;
// }

.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  > .pro-inner-list-item
  > .popper-inner {
  padding-top: 0px;
}

/*make the chosed link in sidebar.jsx stand out*/
.faMenuActive {
  // background-color: $darkBlue;
  background-color: #3471f3;
  border-right: 3px solid #f8fafb;
}
.activeIcon {
  background-color: #3471f3;
}

.pro-sidebar .menuItemActive a,
.pro-sidebar .menuItemActive a:hover {
  color: $white;
}
.pro-menu-item .menuItemActive > .pro-inner-item > .pro-item-content {
  border-left: 3px solid;
  border-color: #005dbf;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item {
  padding: 5px 50px 5px 50px;
}
.popper-element {
  display: none;
}

// .collapsed .icons-sidebar {
//   filter: grayscale(90%) opacity(40%);
// }

.pro-sidebar:not(.collapsed) .Hamburger-sidebar {
  filter: opacity(0%);
}

.pro-menu .pro-menu-item > .pro-inner-item:first-child {
  padding-right: 10px;
}
.pro-sidebar .pro-menu {
  padding-top: 0;
}
.collapsed .CruzBar {
  filter: opacity(0%);
  width: 0px;
  height: 0px;
}

.activeHover:hover > .pro-inner-item > .pro-item-content {
  border-left: 3px solid;
  // border-color: #005dbf;
}
.adminSideBar {
  margin-top: 25px;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
  padding-top: 0px;
  padding-bottom: 10px;
}

.bottomLineSideBar {
  border-bottom: "1px solid";
  border-color: white;
}
// .pro-inner-item-active {
//   background-color: red;
// }
