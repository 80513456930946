@import "../../index.scss";
@import url("https://fonts.googleapis.com/css?family=Mukta:700");

@mixin transition(
  $property: all,
  $duration: 0.45s,
  $ease: cubic-bezier(0.65, 0, 0.076, 1)
) {
  transition: $property $duration $ease;
}
h1 {
  font-size: 45px;
  color: $darkBlue;
  margin-top: 5%;
}
.disable{
  display: none;
}
.filter-svg {
  width: 1rem;
  z-index: 2;
  position: absolute;
  top: 0.2em;
  left: 0.5em;
}
.col-md-12 {
  span {
    position: relative;
  }
}
.background-ttn {
  .container {
    .row {
      h1 {
        margin-left: 4.6%;
    margin-bottom: 1.5rem;
      }
    }
  }
}
.background-ttn {
  .container {
    .row {
      .col-md-12 {
        width: 80%;
        margin: auto;
      }
    }
  }
}
.popUp-ul {
  margin: 0 !important;
  list-style: none;
  padding-left: 0 ;
}
[role=tooltip].popup-content{
width: 130px ;
}
.icons-popUp {
  width: 1rem;
  margin-right: 0.5rem;
}
.icons-svg {
  width: 1.5rem;
  //margin: 10px;
  margin-top: 1rem;
  float: right;
}
.icons-add{
  margin-right: 1rem;
}
.icons-export{
margin-right: 1.9rem;
}
.icons-gear {
  width: 1.5rem;
  margin: 5px;
}
.top-h1 {
  margin-top: 3rem;
}
.bottom-margin-10{
  margin-bottom: .6rem;
}
.w-1-rem{
  width: 1rem;
}
.row-idx-blue {
      height: 7rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #e6ecf3;
      position: relative;
      .absolut-izquierda {
          position: absolute;
          left: 0%;
          .azulete {
              color: $darkBlue;
              font-weight: bolder;
              margin-bottom: 5px;
          }
      }
      .absolut-derecha {
          position: absolute;
          right: 0%;
          top: 33%;
      }
  }
.user-table{
    top: 3rem;
    font-family: 'Roboto';
    font-weight: 400;
    font-style: normal;
    border-collapse: collapse;
    width: 100%;
    border-radius: 15px;
    /* add this */
    margin-top: 1rem;
    text-align: center;
}
.user-table tr {
  vertical-align: middle;
}
.tabla-clientes {
  /* bottom: -6%; */
  top: 3rem;
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  border-collapse: collapse;
  width: 100%;
  border-radius: 15px;
  /* add this */
  overflow: hidden;
  margin-top: 1rem;
  text-align: center;
}
.circle {
  background: $greenEnabled;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  margin: auto;
}
.circle-icon {
  background: $greenEnabled;
  border-radius: 50%;
  width: 0.8rem;
  height: 0.8rem;
  margin-top: 15px;
  margin-bottom: 15px;
}
.circle-red {
  background: #ec1109;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  margin: auto;
}
.circle-red-icon {
  background: #ec1109;
  border-radius: 50%;
  width: 0.8rem;
  height: 0.8rem;
  margin-top: 15px;
  margin-bottom: 15px;
}
.link-icon {
  position: absolute;
  top: 2.4rem;
  margin-left: 1.5rem;
}
.search-users {
  border: 3px solid $clearBlue !important;
  border-radius: 5px;
  padding-left: 2rem;
}
.search-users-alt {
  border: 3px solid $clearBlue !important;
  border-radius: 5px;
  padding-left: .5rem;
}
.table .thead-light th {
  color: #fff;
  background-color: $darkBlue;
}
// .table-striped tbody tr:nth-of-type(odd){
//     background-color: #99b2cc;
// }
.btn-agregar {
  position: absolute;
  /* left: 0rem; */
  top: 17rem;
  right: 0;
}
.filter-select {
  height: 2rem;
  margin-left: 1rem;
  border: 3px solid $clearBlue !important;
  border-radius: 5px;
}
.filter-select-2 {
  height: 2rem;
  margin-left: 0.5rem;
  border: 3px solid $clearBlue !important;
  border-radius: 5px;
}
button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  &.learn-more {
    width: 12rem;
    height: auto;
    margin-left: 0.5rem;
    margin-bottom: 4px;
    .circle {
      @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
      position: relative;
      display: block;
      margin: 0;
      width: 2rem;
      height: 2rem;
      background: $darkBlue;
      border-radius: 1.625rem;
      .icon {
        @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        background: $white;
        &.arrow {
          @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
          left: 0.625rem;
          width: 1.125rem;
          height: 0.125rem;
          background: none;
          &::before {
            position: absolute;
            content: "";
            top: -0.25rem;
            right: 0.0625rem;
            width: 0.625rem;
            height: 0.625rem;
            border-top: 0.125rem solid #fff;
            border-right: 0.125rem solid #fff;
            transform: rotate(45deg);
          }
        }
      }
    }
    .button-text {
      @include transition(all 0.45s cubic-bezier(0.65, 0, 0.076, 1));
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      /* padding: 0.75rem 0; */
      padding-top: 0.2rem;
      margin: 0 0 0 1.85rem;
      color: #282936;
      font-weight: 700;
      line-height: 1.6;
      text-align: center;
      text-transform: uppercase;
    }
  }
  &:hover {
    .circle {
      width: 100%;
      .icon {
        &.arrow {
          background: $white;
          transform: translate(1rem, 0);
        }
      }
    }
    .button-text {
      color: $white;
    }
  }
}

.search-icon{
  width: 2rem;
  position: absolute;
    margin-left: 1%;
}