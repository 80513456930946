@import "../../index.scss";
@import "../../styleConstants.scss";
@import url("https://fonts.googleapis.com/css?family=Mukta:700");

.label-inputs {
  font-size: 1rem;
  margin-bottom: 0;
}
h2 {
  color: #ffffff;
  padding: 2% 0;
}
.close-icon-modal {
  position: absolute;
  top: 4%;
  right: 5%;
}
.modal-header {
  padding: 1rem 0 0 0 !important;
}
.select-register {
  height: 27px;
  width: 100%;
  border: 1.5px solid #01a0f6;
  border-radius: 5px;
  flex: 1;
}
.select-register:disabled {
  //border: 1.5px solid #a9abae;
  background-color: $diabledBg;
  color: $darkGray;
}
.contenedor-flex {
  display: flex;
  flex-wrap: wrap;
}
.contenedor-input {
  margin: 1rem;
}

.register-inputs-wrapper {
  display: flex;
  flex-direction: column;
}
.register-inputs {
  border: 1.5px solid #01a0f6;
  border-radius: 5px;
  width: 100%;
  padding: 0 0.5rem;
  height: 27px;
}

.register-inputs--clearBlue {
  border: 1.5px solid $clearBlue;
}
.register-inputsClear {
  border: 1.5px solid $clearBlue;
  border-radius: 5px;
  width: 100%;
  padding: 0 0.5rem;
  flex: 1;
}

.register-inputs:disabled {
  //border: 1.5px solid #a9abae;
  background-color: $diabledBg;
  color: $darkGray;
}

.register-inputs-big {
  border: 1.5px solid #1c6ea4;
  border-radius: 5px;
  width: 100%;
  padding: 0 0.5rem;
  height: 2.6rem;
}

.iFamily-input {
  border: 3px solid $clearBlue !important;
  border-radius: 5px;
  width: 94%;
}

.div-header-modal {
  width: 100%;
  text-align: center;
  background-color: $darkBlue;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.niifSelectMod {
  flex: 1 1;
  margin-right: 12px;
}

.icon-consumption {
  width: 1.5rem;
}

.register-inputs-big {
  border: 1.5px solid $darkBlue;
  border-radius: 5px;
  width: 100%;
  padding: 0 0.5rem;
  height: 2.6rem;
}

@media (min-width: 992px) {
  .close-icon-modal {
    top: 1.1rem;
  }
}

.modal-sm2 {
  width: 400px;
}
.register-inputs::-webkit-calendar-picker-indicator {
  filter: invert(15%) sepia(92%) saturate(5062%) hue-rotate(201deg)
    brightness(93%) contrast(101%);
}

.register-inputsTextArea {
  border: 1.5px solid #01a0f6;
  border-radius: 5px;
  width: 100%;
  padding: 0 0.5rem;
  height: 50px;
}
.modal-red {
  color: #ec1c24;
}
.modal-green {
  color: #00a551;
}

.modal-400 {
  width: 400px;
  max-width: none;
}
.modal-500 {
  width: 500px;
  max-width: none;
}
.modal-440 {
  width: 440px;
  max-width: none;
}
.modal-550 {
  width: 550px;
  max-width: none;
}
.modal-700 {
  width: 700px;
  max-width: none;
}
.modal-750 {
  width: 700px;
  max-width: none;
}
.modal-940 {
  width: 940px;
  max-width: none;
}
.modal-1210 {
  width: 1210px;
  max-width: none;
}

.register-inputs-readonly {
  border: 1.5px solid #01a0f6;
  border-radius: 5px;
  width: 100%;
  padding: 0 0.5rem;
  height: 27px;
  background-color: #f5f7fa;
}
.label_inputs_new3 {
  color: #58595b;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 15.53px;
}
