@import '../../index.scss';

// Transparent background for text inputs
// input[type="text"],input[type="number"],input[type="password"]
// {
//     background: transparent;
//     border: none;
// }

.header-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 5px solid $clearBlue;
    max-height: 80px;
}

.header-container-inicio{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 80px;
    background: linear-gradient(-90deg, #ffff 50%, #F8FAFB 50%);
}

.user-img-header{
    width: 100%;
    max-width: 60px;
    height: auto;
    border:2.5px solid $darkBlue;
    border-radius: 100%;
}
.icon-menu-header{
    font-size: 2rem;
    text-align: center;
    color: $darkBlue;
    border-radius: 10%;
    border:2px solid $darkBlue;
    display: inline-block;
    margin: 25px 0;
}
.hoverPointer:hover{
    cursor: pointer;
}
.popUp-user-profile{
    position: absolute;
    border:1px solid $darkBlue;
    border-radius: 10px;
    top: 2rem;
    right:5rem;
    max-width: 30rem;
    overflow: hidden;
    background-color: white;
    padding: 1rem;    
    z-index: 2000;
}
.container-input-header{
    min-width: 17rem;
}
.text-section{
    display: flex;
    flex: auto
}
.input-text-header{
    margin-left: 2rem !important;
    font-weight: 700;
    color:$darkBlue;
    overflow: hidden;
}
.text-section-style{
    margin-top: 18px;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 24px;
    font-style: normal;
    color:#CECECE;
}
.input-edit-max-width{
    max-width: 11rem;
}
.input-user-icon-edit-profile{
    position: absolute;
    transform: scale(3);
    z-index: 2002;
    left: 1rem;
    top: 1.5rem;
}
.input-edit-icon-profile{
    position: absolute;
    transform: scale(3);
    right: 1rem;
    top: 1.5rem;
}
.border-edit-input::before{
    content: '';
    border-left: 3px solid $darkBlue;
    position: absolute;
    right:20%;
    height: 100%;
}
.link-text{
    text-decoration: none;
    color:inherit;
}