.fullLayout{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.fullLayoutBody{
    display: flex;
    flex: 1;
    align-items: stretch;
    overflow: hidden;
}

.fullLayoutWorkArea{
    display: flex;
    flex: 1;
    overflow-y: overlay;
}

.fullLayoutSideBar{
    overflow: hidden;
}