
.accountBoxWrap{
    display: flex;
    flex-wrap:wrap;
    justify-content: center;
}

.titleContainer{
    display: flex;
    justify-content: center;
    margin: 0px 0px 0px;

}

.centralContainer{
    display: flex;
    flex-wrap:wrap;
    justify-content: center;
    margin-top: 11rem;
    padding: 0 146px 0 146px;
}
.container{
    width: 100%;
    padding: 0 135px;
    display:flex;
    flex-direction: column;
}

.title{
    margin-top: 3rem;
    margin-bottom: 11px;
    font-weight: Bold;
    font-size: 40px;
    line-height: 52px;
}

.subtitle{
    font-style: normal;
    font-size: 24px;
    line-height: 31px;
    color:#58595B;
}

.flex1{
    /* flex:1; */
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 190px;
    height: 121px;
    border: 1px solid #CECECE;
    background-color: #F5F7FA;
    background-color: #fff;
    border-radius: 15px;
    margin: 28px;
    cursor: pointer;
}

.flex1:hover{
    /*background-color: rgba(2, 55, 177, 0.1);*/
    background-color: #F5F7FA;
}

.classIcon{
    width: 40px;
    height: 40px;
    flex:3;
    margin: 0 auto;
    filter: brightness(0) saturate(100%) invert(86%) sepia(0%) saturate(2%) hue-rotate(33deg) brightness(97%) contrast(92%);
}

.classTitle{
    flex:2;
    margin: 0 auto;
    color: #58595B;
    font-weight: Bold;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
}
