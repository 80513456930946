@import '../../index.scss';
.container-form-login{
    display: flex;
    flex-direction: column;
    justify-content: center;
    // margin:50px auto;
    width: 100%;
    max-width: 386px;
    text-align: center;
}
.container-form-login img{
    margin: auto;
}



div#root>div.row{
    width: 100%;
}

.myCheckboxInput{
    width: 20px;
    height: 20px;
    border-radius: 4px;
    vertical-align: middle;
    margin-top: 12px;
    background-color: #005DBF;
}

.marginNew{
    margin-right: 10px
}

.textAbove{
    display: inline;
    margin-top: 10px;
}


.carousel-content {
    position: absolute;
    bottom: 10%;
    left: 5%;
    z-index: 20;
    color: white;
    text-shadow: 0 1px 2px rgba(0,0,0,.6);
  }
.MarginImage{
    margin-bottom: 69px;
}
.container-form-login input{
    max-width: 375px;
    margin: 10px auto;
}
#icon-user-input-login{
    color:$darkBlue;
    font-size: 1rem;
}
.form-group-login{
    display: flex;
    flex-direction: column;
}
.input-form-login{
    border:1px solid $darkBlue;
    padding: 10px;
    border-radius:5px;
    width: 100%;
   
}
.input-user-icon{
    position: absolute;
    transform: scale(1);
    z-index: 2;
    left: 1.5rem;
    top: 1.5rem;
}
.input-text-login{
    margin-left: 3rem !important;
    font-weight: 700;
    font-style: bold;
    font-size: 15px;
    color:$darkBlue;
    line-height: 19,41px;
    cursor: pointer;
    top: 1.5rem;
    margin-top: 2px;
}

.input-text-login::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color:#CECECE;
    opacity: 1; /* Firefox */
    font-style: "bold";
    font-size: 15px;
    font-weight: 700;
    font-variant: normal;
    line-height: 19,41px;
}

.input-text-login{
    outline-style: none;
    max-height: 56px;
}


.button {
    border-radius: 7px;
    border: 1px solid #01A0F6;
    background-color: #01A0F6;
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
    font-family:'Roboto'
    
}
  
.button:hover {
    border: 1px solid #005DBF;
    background-color: #005DBF;
    color: #ffffff;
    font-size: 20px;
  }
  
.button:disabled,
button[disabled]{
    border: 1px solid #CECECE;
    background-color: #CECECE;
    color: #ffffff;
    font-weight: 700;
    font-size: 20px;
    font-family:'Roboto'
}
.carousel-inner > .item {
    position: relative;
    display: none;
    -webkit-transition: 0.4s ease-in-out left;
    -moz-transition: 0.4s ease-in-out left;
    -o-transition: 0.4s ease-in-out left;
    transition: 0.4s ease-in-out left;
}



.carousel-fade .carousel-item {
    opacity: 0;
    transition-duration: 0.6s;
    transition-property: opacity;
}
.carousel-fade  .carousel-item.active,
.carousel-fade  .carousel-item-next.carousel-item-left,
.carousel-fade  .carousel-item-prev.carousel-item-right {
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade  .active.carousel-item-right {
 opacity: 0;
}
.carousel-fade  .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade  .active.carousel-item-prev {
 transform: translateX(0);
 transform: translate3d(0, 0, 0);
}

.carousel-item {
    position: relative;
    display:none;
    float: left;
    width: 100%;
    margin-right: -100%;
    backface-visibility:hidden ;
  }
.carousel .carousel-indicators li {
    background-color:#CECECE;
    width: 79px;
    height: 4px;
}
.carousel .carousel-indicators li.active {background-color: #01A0F6 ;}
.carouselText{
    color: #58595B;
}
.input-blue-border {
    border: 2.5px solid #01A0F6;
    border-radius: 10px;
    
}
.input-blue-border:hover {
    border: 2.5px solid #005DBF;
    border-radius: 10px;
}

.form-control:focus {
    color: #005DBF;
}
.mb-3 {
    margin-bottom: 8px!important;
}
.mt-3 {
    margin-top: 8px!important;
}
.myCheckbox input:checked{
  background-color: #005DBF;
}
.container-50{
    display:flex;
    justify-content:center;
    align-items:center;
}

.centeredContainer {
    /*width: 40%;*/
    margin: 0 auto;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
  }
  
