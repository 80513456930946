@import "../../styleConstants.scss";

.genericTable {
  padding: 0 1.5rem;
  top: 3rem;
  font-family: "Roboto";
  font-weight: 400;
  font-style: normal;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  border-radius: 15px;
  margin-top: 1rem;
  text-align: center;
}

.genericTable > tbody > tr > td {
  border: none;
  padding: 0.4rem 0;
  text-transform: capitalize;
}

.genericTable > tbody > tr {
  background-color: $white;
}

.genericTable > tbody > tr:nth-of-type(even) {
  background-color: $tableEven;
}

.genericTable > tbody > tr:hover {
  background-color: $tableHover;
  transition: 0.3s all ease-in-out;
}

.genericTable > tbody > tr > td {
  text-align: left;
  font-size: 0.8rem;
  font-weight: 500;
  vertical-align: middle;
}

.genericTable > tbody > tr > td:first-child {
  padding-left: 2.5rem;
}

.theadLight {
  color: $white;
  background-color: $darkBlue;
}

.theadLight > tr > th {
  padding: 0.5rem 0;
}

.groupElems {
  // max-width: 7rem;
  display: flex;
  margin-right: 0;
  margin-left: auto;
  justify-content: space-between;
  vertical-align: middle;
}
.groupElemsWithoutMarginL {
  max-width: 5rem;
  display: flex;
  margin-right: 0;
  justify-content: space-between;
  vertical-align: middle;
}

.iconSvg {
  width: 1.7rem;
  margin: 0.5rem;
  margin-top: 0.3rem;
}
.iconSvgSmall {
  width: 1rem;
  margin: 0 0.3rem;
}
.iconSvgMedium {
  width: 3.5rem;
  margin: 0;
}
.iconSvgMargin {
  margin: 0 0.5rem;
}
.iconSvgMargin2 {
  margin-bottom: 2px;
  margin-left: 5px;
}
.iconSvg:hover,
.iconSvgSmall:hover,
.iconSvgMedium:hover {
  cursor: pointer;
  opacity: 0.8;
}

.iconsGear {
  width: 2rem;
  margin: 0 0.5rem;
}
.circleGreen {
  background: $greenEnabled;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  margin: auto;
}
.circleRed {
  background: red;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  margin: auto;
  //margin: auto .5rem;
}

.cursorFlecha {
  cursor: default;
}
.circleGreenAuto {
  background: $greenEnabled;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  margin: auto;
}
.circleRedAuto {
  background: red;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  margin: auto;
}
.circleYellowAuto {
  background: #f2c94c;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  margin: auto;
}
.circleBlackAuto {
  background: #58595b;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  margin: auto;
}

.mainRow {
  display: inline-flex;
  width: 100%;
  vertical-align: middle;
  justify-content: space-between;
  //padding: 0 .5rem;
}

.container {
  width: 100%;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
}

.title {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.tableArea {
  //margin:0 5rem;
  margin: 0 76px;
}
.searchUsersAlt {
  border: 1.5px solid transparent;
  border-radius: 5px;
  padding-left: 0.5rem;
  background-color: rgba(111, 156, 255, 0.15);
}
.searchUsersAlt::placeholder {
  color: #3471f3 !important;
}

.inputText {
  border: 1.5px solid $darkBlue;
  flex: 1;
  border-radius: 5px;
  padding-left: 0.5rem;
}

.crudModalRow {
  display: flex;
}

.crudModalItemTiny {
  flex: 3;
  margin-right: 0.4rem;
  display: flex;
  flex-direction: column;
}
.crudModalItemSmall {
  flex: 7;
  display: flex;
  flex-direction: column;
}
.crudModalItemBig {
  flex: 10;
  margin: 0 0.2rem;
  display: flex;
  flex-direction: row;
}
.crudModalrightMargin {
  margin-right: 0.4rem;
}

.crudModalLabel {
  font-size: 0.75rem;
  margin: 0.4rem 0.3rem 0 0.3rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: $darkBlue;
}

.crudModalInputDate {
  font-size: 1vw;
  flex: 1 1 auto;
}

.crudModalInputDate::-webkit-calendar-picker-indicator {
  filter: invert(15%) sepia(92%) saturate(5062%) hue-rotate(201deg)
    brightness(93%) contrast(101%);
}

.crudModalZonaCuenta {
  display: flex;
  flex-direction: column;
  background-color: #ddd;
  border-radius: 0.5rem;
  margin-top: 1rem;
  padding: 0.5rem;
}

.crudModalZonaPerfiles {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  padding: 0.5rem 2rem;
}

.crudModalPerfilesWrap {
  display: flex;
  flex-wrap: wrap;
  padding-left: 1rem;
}

.myCheckbox {
  margin: 0 1rem;
}

.myCheckboxLabel {
  margin-left: 0.5rem;
  text-transform: capitalize;
  vertical-align: middle;
}

.myCheckboxInput {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  vertical-align: middle;
}
.flex3 {
  flex: 3;
}
.flex1 {
  flex: 1;
  display: flex;
  padding: 0 0.3rem;
}

.textCenter,
.genericTable > tbody > tr > .textCenter {
  text-align: center;
}

.textleft {
  text-align: left;
}

.btnPrimary {
  background-color: $clearBlue;
  border: 1.5px solid $clearBlue;
  font-family: "Roboto";
  color: #fff;
  min-width: 110px;
  font-weight: 700;
  font-size: 15px;
  line-height: 19.41px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.btnPrimary:hover {
  background-color: $darkBlue;
  border: 1.5px solid $darkBlue;
  color: #fff;
}

.btnPrimary:disabled {
  background-color: $clearGray;
  border: 1.5px solid $clearGray;
  color: #fff;
  opacity: 1;
}

.btnSecondary {
  background-color: #fff;
  border: 1.5px solid $clearBlue;
  font-family: "Roboto";
  color: $clearBlue;
  min-width: 110px;
  font-weight: 700;
  font-size: 15px;
  line-height: 19.41px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.btnSecondary:hover {
  background-color: $clearBlue;
  border: 1.5px solid $clearBlue;
  color: #fff;
}

.btnSecondary:disabled {
  background-color: #fff;
  border: 1.5px solid $clearGray;
  color: $clearGray;
  opacity: 1;
}

//button extra for modal
//111111111111111111111111111111111111
.buttonExtra1 {
  background-color: #fff;
  border: 1.5px solid $darkBlue;
  box-sizing: border-box;
  border-radius: 5px;

  font-family: "Roboto";
  color: $darkBlue;
  min-width: 130px;
  font-weight: 700;
  font-size: 15px;
  line-height: 19.41px;
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
  justify-content: center;
}
.buttonExtra1:hover {
  background-color: $darkBlue;
  border: 1.5px solid $darkBlue;
  color: #fff;
}
.buttonExtra1:hover > img {
  filter: brightness(0) invert(1);
}
.buttonExtra1:disabled {
  background-color: #fff;
  border: 1.5px solid $clearGray;
  color: $clearGray;
  opacity: 1;
}
.buttonExtra1:disabled > {
  filter: brightness(0) saturate(100%) invert(83%) sepia(0%) saturate(3%)
    hue-rotate(198deg) brightness(101%) contrast(91%);
}

//2222222222222222222222222222222222222
.buttonExtra2 {
  background-color: #fff;
  border: 1.5px solid $clearBlue;
  box-sizing: border-box;
  border-radius: 5px;

  font-family: "Roboto";
  color: $clearBlue;
  min-width: 130px;
  font-weight: 700;
  font-size: 15px;
  line-height: 19.41px;
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
}
.buttonExtra2:hover {
  background-color: $clearBlue;
  border: 1.5px solid $clearBlue;
  color: #fff;
}

//3333333333333333333333333333333333333333333333
.buttonExtra3 {
  background-color: $darkBlue;
  border: 1.5px solid $darkBlue;
  box-sizing: border-box;
  border-radius: 5px;

  font-family: "Roboto";
  color: #fff;
  min-width: 130px;
  font-weight: 700;
  font-size: 15px;
  line-height: 19.41px;
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
}
.buttonExtra3:hover {
  background-color: $darkerBlue;
  border: 1.5px solid $darkerBlue;
  color: #fff;
}

.buttonExtra2:disabled,
.buttonExtra3:disabled {
  background-color: $clearGray;
  border: 1.5px solid $clearGray;
  color: #fff;
  opacity: 1;
}

.buttonExtra2:hover > img,
.buttonExtra2:disabled > img,
.buttonExtra3 > img {
  filter: brightness(0) invert(1);
}

.buttonExtra1 > img,
.buttonExtra2 > img,
.buttonExtra3 > img {
  height: 15px;
  vertical-align: middle;
  margin: auto 8px auto 0px;
}
//end extra buttons

.deleteicon {
  position: relative;
  flex: 1;
  display: flex;
}
.deleteicon span {
  position: absolute;
  display: block;
  top: -8px;
  right: -8px;
  width: 20px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  color: #fff;
  background: $clearBlue;
  cursor: pointer;
  border-radius: 50%;
}
.deleteicon input {
  flex: 1;
}
.shade {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
}

.clearBlueText {
  color: $sideBarBlue;
}
.theadClearBlue {
  background-color: $sideBarBlue;
  color: $white;
}
.fourBorderRadiuses {
  border-radius: 10px;
  border-color: $tenueBlue;
}

.centeredContainer {
  width: 90%;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
}

.marginLeftsix {
  margin-left: 6%;
}
.bgColorClearBlue {
  background-color: $bgFormBlue;
  // background-color: 'red';
}
.boxShadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.fZSmall {
  font-size: 12px;
}

.slideEffectbtn {
  background-color: white;
  margin-left: 15px;
  border-radius: 15px 15px 0 0;
}
.itemTextAccordion {
  text-align: left;
}
.blueBackgroundCard {
  background-color: rgba(0, 93, 191, 0.05);
  margin-top: 3px;
}

.autoSelect {
  margin-left: 30%;
  margin-top: 13%;
  width: 50%;
  border-radius: 50%;
  /* border-color: #ff8f8f; */
  border: 1px solid gray;
  background-color: white;
}
.noBorderColor {
  border-color: inherit;
}
.boxLessShadow {
  box-shadow: #c5bfbf40 0px 1px 10px;
}
.calendarRadius {
  border: 1px solid #01a0f6;
  border-radius: 10px;
  // border-color: #01a0f6;
}
.tableBorder {
  border: 1px solid gray;
}
