.fwCards {
  font-size: smaller;
  border-radius: 10px;
}

.boxShadow {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  /* background-color: red; */
}
.boxShadowBlue, .boxShadowGray{
  border: none;
}
.boxShadowBlue{
  box-shadow: 0px 4px 15px rgba(77, 132, 254, 0.5);
}
.boxShadowGray{
  box-shadow: 0px 4px 30px rgba(55, 61, 80, 0.07);
}

.borderAdviceBox {
  border-radius: 15px;
}
.titlesHomePage{
  color: #373D50;
}
.subtitlesHomePage{
  color: #8B8D94;
}
.cardBorderRadiusHome{
  border-radius:10px !important;
}